/* ====================

P R I M A R Y   N A V

====================== */

export const titleData = [
  { id: 1, title: 'Home' },
  { id: 2, title: 'Work history' },
  { id: 3, title: 'Case studies' },
  { id: 4, title: 'Principles' },
  { id: 5, title: 'Contact' },
]

/* ====================

W O R K  H I S T O R Y

====================== */

export const timelineData = [
  {
    id: 12,
    date: {
      from: "May '24",
      to: 'Present',
    },
    title: {
      company: 'Brandwatch',
      description:
        'Brandwatch is an industry-leading B2B SaaS platform offering social listening, social media management and media intelligence.',
      link: 'https://www.brandwatch.com',
      location: 'Remote, UK',
      role: 'Director UX Research',
    },
    details: [
      'Responsible for leading a growing team of talented researchers working across three business units, cultivating career growth through mentorship, coaching, and skill development',
      'Work closely with product and design leadership to ensure research is consistently actionable, drives roadmaps, and has measurable user and business impact',
      'Build relationships with core functions across the organization to align research initiatives with company wide goals and grow user-centered maturity',
      'Continuously work to shape UX research as a practise alongside ReOps and other operations teams',
    ],
  },
  {
    id: 11,
    date: {
      from: "Feb '22",
      to: "May '24",
    },
    title: {
      role: 'UX Research Manager & Lead',
    },
    details: [
      'Managed a small team of researchers working across a single business unit',
      'Following a merger, I led research for a program of work merging two legacy products into one shared platform, including rearchitecting user management',
      'Led strategic programs of research including developing a JTBD style user segmentation',
      'Designed and built function prototypes in react to test early design ideas',
    ],
  },
  {
    id: 10,
    date: {
      from: "Oct '20",
      to: "Feb '22",
    },
    title: {
      role: 'Senior User Researcher',
    },
    details: [
      "Led a phase of discovery looking into the 'admin experience' which informed a tribe's entire roadmap for a full year",
      'Delivered an iterative and evaluative project for the data management tribe, taking a prototype from low fidelity through four iterations to high fidelity designs',
      'Established a process to enable all researchers to run a virtual observation room during research, to encourage as many stakeholders to observe sessions as possible',
    ],
  },
  {
    id: 9,
    date: {
      from: "Oct '21",
      to: 'Present',
    },
    title: {
      company: 'SUNY Westchester Community College',
      description:
        'WCC is a public community college and is part of the State University of New York (SUNY) system.',
      link: 'https://www.sunywcc.edu',
      location: 'New York, USA',
      role: 'Adjunct Professor',
    },
    details: [
      'Designed and led a part-time UX Fundamentals class introducing students to core concepts of user-centered design',
      'Designed and led a practical portfolio development class solving real-world problems',
      'Continue to mentor past students in job seeking and further developing UCD as a practise',
    ],
  },
  {
    id: 8,
    date: {
      from: "Aug '19",
      to: "Feb '20",
    },
    title: {
      company: 'Babylon Health',
      description:
        'Babylon Health (now eMed) is an end-to-end healthcare service that uses A.I. to transform the doctor-patient relationship.',
      link: 'https://www.babylonhealth.com',
      location: 'London, UK',
      role: 'Senior User Researcher (contract)',
    },
    details: [
      'Led a 6-month program of explorative research that shaped roadmaps and guided the strategic direction of their flagship product – an AI-powered symptom checker',
      'Designed and hosted an exhibition to showcase research insights to the entire 1500+ organization',
      'Worked with sick and vulnerable participants where extra care had to be taken to ensure participant and researcher safety',
      'Supported efforts to test the chatbot algorithm’s accuracy and relevance of information',
    ],
    caseStudy: '/case-studies/babylon-health',
  },
  {
    id: 7,
    date: {
      from: "May '18",
      to: "Feb '19",
    },
    title: {
      company: 'The Home Office',
      description:
        'The Home Office is an arm of the UK Government, similar to Homeland Security in the US.',
      link: 'https://www.gov.uk/service-manual/service-standard',
      location: 'London, UK',
      role: 'Senior User Researcher (contract)',
    },
    details: [
      'Formed part of a cross-functional team that designed and delivered a service that has enabled over 6m EU citizens to settle and remain in the UK, following Brexit',
      'Conducted testing with people with access needs such as visual impairment and cognitive disorders',
      'Introduced processes for improved team and task management',
    ],
  },
  {
    id: 6,
    date: {
      from: "Jun '17",
      to: "May '18",
    },
    title: {
      company: 'New Look',
      description:
        'New Look is a leading UK-based fast-fashion retailer, with over 800 stores worldwide and an annual turnover of approx £1.4bn (as of 2018).',
      link: 'https://www.newlook.com/uk',
      location: 'London, UK',
      role: 'Lead User Researcher',
    },
    details: [
      'Built the company’s first-ever UX research function from bottom up, instilling best practises and process for recruitment and research, and working with stakeholders to establish and manage budgets',
      'Established bi-weekly lab sessions to encourage the product team to frequently test ideas and ask questions',
      'Worked closely with analytics and optimization teams to develop a process for rapid and iterative experimentation',
    ],
  },
  {
    id: 5,
    date: {
      from: "Feb '17",
      to: "Oct '17",
    },
    title: {
      company: 'General Assembly',
      description:
        'General Assembly is a private education organization that teaches practical technology skills to entrepreneurs and business professionals.',
      link: 'https://generalassemb.ly/',
      location: 'London, UK',
      role: 'UX Instructor',
    },
    details: [
      'Led a 2-hour evening class once a week for 9 months, introducing students to the basics of user-centered design',
    ],
  },
  {
    id: 4,
    date: {
      from: "Apr '16",
      to: "Jun '17",
    },
    title: {
      company: 'GfK',
      description:
        "GfK is an international consumer research firm delivering marketing and consumer intelligence to the world's biggest brands.",
      link: 'https://www.gfk.com/en-us/home',
      location: 'London, UK',
      role: 'User Experience Consultant',
    },
    details: [
      'Led a contextual study for Amazon Prime visiting warehouses of Amazon sellers throughout the UK, which resulted in Amazon renweing their contract with the consultancy for further research',
      'Delivered a 6-month global persona project for American Express involving the coordination of partner research agencies across 8 international markets',
      'Traveled to India to help VISA launch a mobile payment app in following national demonetization',
    ],
  },
  {
    id: 3,
    date: {
      from: "Mar '15",
      to: "Dec '15",
    },
    title: {
      company: 'Oliver',
      description:
        "Oliver is an advertising agency that designs, builds, and delivers digital media for some of the world's biggest brands.",
      link: 'https://www.oliver.agency',
      location: 'London, UK',
      role: 'Head of Digital',
    },
    details: [
      'Headed up the digital department at a rapidly expanding creative advertising and marketing agency, managing a team of 15 while working to develop the agency’s digital strategy, and promote the department both internally and externally',
      'Developed and embedded efficient workflow and production processes',
      'Educated internal and external stakeholders on the opportunities and limitations of different forms of digital technology',
      'Represented the company and the department at a senior level in front of c-suite clients and stakeholders',
    ],
  },
  {
    id: 2,
    date: {
      from: "Nov '14",
      to: "Mar '15",
    },
    title: {
      role: 'Senior Digital Producer (contract)',
    },
    details: [
      'Ran the digital production efforts for the Beats By Dre account, delivering multiple campaigns across online video and advertising, landing pages, digital OOH and email',
    ],
  },
  {
    id: 1,
    date: {
      from: "Apr '12",
      to: "Oct '14",
    },
    title: {
      company: 'Tag Worldwide',
      description:
        'Tag Worldwide is a global marketing agency that provides creative production and sourcing services for brands and agencies.',
      link: 'https://www.tagww.com',
      location: 'New York, USA',
      role: 'Director of Digital Project Management',
    },
    details: [
      'Led the digital project management team at Tag New York, the US arm of the global advertising production agency Tag Worldwide',
      'Helped build a digital production team over three years, taking headcount from four to twenty-five',
      'At various points throughout tenure, headed up a team of up to 10 individuals across project management, design, and development, often running a lean team and ensuring a healthy P&L',
      'Managed resource planning and forecasting',
      'Devised and implemented lasting workflows and processes that aided department productivity',
      'Mentored and helped the development of junior and senior project managers on client management, creative production, and project management practices',
    ],
  },
]

/* ====================

C A S E  S T U D Y  C A R D S

====================== */

export const caseStudyData = [
  {
    id: 1,
    url: 'babylon-health',
    headline: 'Redefining the purpose of an A.I. health service',
    body: 'Using discovery research to redefine how healthcare can meet the needs of everyone.',
    image: 'case_card_images/bablyon-cc',
    bgColor: 'tealpink',
    copyPlacement: 'left',
    imgPositionMobile: '0px',
    imgPositionDesktop: '-10px',
    imgWidth: '40%',
    role: ['Research', 'Design'],
    type: 'client',
  },
  {
    id: 2,
    url: 'the-home-office',
    headline: 'Delivering the EU settlement service',
    body: 'Delivering a post-Brexit immigration service that meets the needs of 4.2m EU citizens.',
    image: 'case_card_images/euss-cc',
    bgColor: 'blue',
    copyPlacement: 'right',
    imgPositionMobile: '-10px',
    imgPositionDesktop: '-10px',
    imgWidth: '50%',
    role: ['Research'],
    type: 'client',
  },
  // {
  //   id: 3,
  //   url: 'new-look',
  //   headline: 'Building a research function in Fast Fashion',
  //   body: 'Using discovery research to redefine how healthcare can meet the needs of everyone.',
  //   image: 'newlook/newlook-cs',
  //   bgColor: 'green',
  //   copyPlacement: 'left',
  //   role: ['Design', 'Development'],
  //   type: 'client',
  // },
  {
    id: 4,
    url: 'amazon-prime',
    headline: 'Exporting Amazon’s prime processes',
    body: 'Helping Amazon improve their prime partner’s fulfilment times.',
    image: 'case_card_images/amazon-cc',
    bgColor: 'green',
    copyPlacement: 'left',
    imgPositionMobile: '0px',
    imgPositionDesktop: '-10px',
    imgWidth: '45%',
    role: ['Research'],
    type: 'client',
  },
  // {
  //   id: 5,
  //   url: 'fantasy-football',
  //   headline: 'Building a fantasy football app',
  //   body: 'Designing and building a fantasy football app from scratch.',
  //   image: 'case_card_images/ff-cc',
  //   bgColor: 'yellow',
  //   copyPlacement: 'right',
  //   imgPositionMobile: '4px',
  //   imgPositionDesktop: '4px',
  //   imgWidth: '40%',
  //   role: ['Design', 'Development'],
  //   type: 'side',
  // },
  // {
  //   id: 6,
  //   url: 'myles-away',
  //   headline: 'Helping New Yorkers escape the city',
  //   body: 'Building a hotel aggregator to help New Yorkers find a place to stay.',
  //   image: 'case_card_images/myles-cc',
  //   bgColor: 'peach',
  //   copyPlacement: 'left',
  //   imgPositionMobile: '14px',
  //   imgPositionDesktop: '11px',
  //   imgWidth: '40%',
  //   role: ['Development'],
  //   type: 'side',
  // },
]

/* ====================

P R I N C I P L E S

====================== */

export const principleData = [
  {
    title: 'Define the problem',
    details:
      "If we can’t clearly articulate the problem we're trying to solve and why it's a problem, then we don’t know enough about the problem. If we don't know enough about the problem then we'll be reduced to guessing the right solution. Listen to, observe, and learn from people, before jumping to solutions.",
  },
  {
    title: 'Start with what we know',
    details:
      'Often, I see initiatives start with primary research. This ignores the fact that you may have access to existing data that can help answer your research questions, or give you a solid platform from which to launch your inquiry.',
  },
  {
    title: 'Research should help not hinder',
    details:
      'Research should never be a blocker of progress. We rarely have enough time or money for research to support every decision, so instead, we need to focus our energies in areas that carry the greatest risk if we get our design decisions wrong.',
  },
  {
    title: 'Clarity > Accuracy',
    details:
      'We work in the product design world. We are not in the business of academic or scientific research. We need to think about how our research insights can give us the clarity to make better design decisions, instead of feeling endlessly anxious about the scientific accuracy of our data.',
    cite1: 'This took me a while to arrive at, but credit to ',
    citeUrl: 'https://www.myddelton.co.uk',
    citeAnchor: 'Will Myddelton',
    cite2: ' for helping me get there.',
  },
  {
    title: 'Tell the story',
    details:
      "Effective research is only as good as the story through which it's told. Always ensure the format in which you communicate your insights and learnings is tailored to your goals and your audience's needs.",
  },
  {
    title: 'Research is a team sport',
    details:
      "This is borrowed from the GDS playbook and is a mantra I have always tried hard to live by. Having repeatedly seen the value of involving the whole team in the research process, I truly believe it's the quickest way to get everyone working towards a set of clearly defined problems and common goals.",
  },
  {
    title: 'Research is continuous',
    details:
      'It doesn’t stop. Your questions should evolve as your product, users, and market evolve.',
  },
  {
    title: 'You are not the user',
    details:
      "And very often no one else in the company is either. Don't assume you understand your user's problems.",
  },
  {
    title: 'Don’t obsess over what the competition is doing',
    details:
      'There are some cases where it can be warranted, but a lot of the time there’s no knowing if a new feature or service shipped by a competitor is based on anything other than an idea that the CEO had in the shower one morning.',
  },
]
