import React from 'react'
import styled from 'styled-components'
import CaseCard from 'components/caseStudies/CaseCard'
import Header from 'components/Header'
import { caseStudyData, titleData } from 'data/data'
import { LayoutWrapper } from 'styles/global'

const Grid = styled.div`
  width: 100%;
  display: grid;
  grid-gap: 1.7em;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  justify-items: center;
  padding-bottom: 40px;
  @media (min-width: 960px) {
    grid-template-columns: 1fr 1fr;
  }
`

const caseStudyComponents = caseStudyData.map(entry => (
  <CaseCard key={entry.id} entry={entry} />
))

export default function CaseStudies() {
  return (
    <>
      <Header text={titleData[2].title} />
      <LayoutWrapper caseStudies>
        <Grid>{caseStudyComponents}</Grid>
      </LayoutWrapper>
    </>
  )
}
