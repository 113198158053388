import React from 'react'
import styled from 'styled-components'
import { caseStudyData } from 'data/data'

const Container = styled.div`
  background: ${({ theme }) => theme.caseStudyFooterTint};
  width: 100%;
  padding: 1rem 0;
`

const Header = styled.h3`
  text-align: center;
  padding-top: 23px;
`

const LinkContainer = styled.div`
  width: 100%;
  text-align: center;
  justify-content: center;
  padding: 10px 0 45px;
  @media (min-width: 960px) {
    padding: 10px 5em;
  }
`
const Anchor = styled.a`
  font-size: 0.9rem;
  font-weight: 700;
  padding: 0.5em 1em;
  line-height: normal;
  text-decoration: none;
  color: inherit;
  opacity: 0.4;
  display: ${props => (props.csid === props.index ? 'none' : 'inline-block')};
  :hover {
    opacity: 1;
    text-decoration: inherit;
  }
`

export default function CaseStudyFooter({ caseStudyId }) {
  return (
    <Container>
      <Header>You got more in you?</Header>
      <LinkContainer caseStudyId={caseStudyId}>
        {caseStudyData.map((casestudy, index) => (
          <Anchor
            key={casestudy.id}
            index={index + 1}
            csid={caseStudyId}
            href={`/case-studies/${casestudy.url}`}>
            {casestudy.headline}
          </Anchor>
        ))}
      </LinkContainer>
    </Container>
  )
}
