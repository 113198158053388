import React from 'react'
import DarkLightToggle from 'components/DarkLightToggle'
import { useSpring, animated } from 'react-spring'
import { NavLink } from 'react-router-dom'
import { titleData } from 'data/data'
import styled from 'styled-components'

const Background = styled.div`
  width: 100vw;
  inset: 0;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
  background: ${({ theme }) => theme.body};
  transition: all 0.25s linear;
`
const MobileMenuWrapper = styled.div`
  width: 100vw;
  height: calc(100 * var(--vh));
  background: ${({ theme }) => theme.body};
  transition: all 0.25s linear;
  position: relative;
  z-index: 10;
  display: grid;
  place-items: center;
  text-align: center;
`
const DesktopNavWrapper = styled.div`
  display: none;
  @media (min-width: 960px) {
    display: grid;
    place-items: end;
    padding-right: 50px;
    position: fixed;
    top: 0;
    right: 0;
    width: 100%;
    background: ${({ theme }) => theme.navBackground};
    z-index: 10000;
    backdrop-filter: blur(5px);
  }
`
const DesktopNav = styled.nav`
  font-family: var(--secondary-font);
  display: none;
  @media (min-width: 960px) {
    display: block;
  }
`
const NavItems = styled.ul`
  font-family: var(--secondary-font);
  display: block;
  text-align: center;
  padding-inline-start: 0px;
  @media (min-width: 960px) {
    display: flex;
  }
  li {
    list-style: none;
    text-decoration: none;
    color: ${({ theme }) => theme.text};
    font-size: 2.5rem;
    margin: 30px;
    font-weight: 700;
    @media (min-width: 960px) {
      margin: 10px;
      font-size: 0.8rem;
      align-self: baseline;
    }
    a {
      transition: all 0.1s linear;
    }
    a:hover {
      color: ${({ theme }) => theme.textHighlight} !important;
      transition: all 0.1s linear;
    }
  }
`

export const MobileMenu = ({ showMenu, openMenu, toggleTheme, theme }) => {
  const animation = useSpring({
    config: {
      duration: 250,
    },
    opacity: showMenu ? 1 : 0,
    transform: showMenu ? `translateY(0%)` : `translateY(2%)`,
  })

  const activeStyleColor =
    theme === 'dark' ? 'var(--color-primary)' : 'var(--color-secondary)'

  const inActiveStyleColor =
    theme === 'dark' ? 'var(--color-light)' : 'var(--color-dark)'

  let activeStyle = {
    textDecoration: 'underline',
    textUnderlineOffset: '3px',
    color: activeStyleColor,
  }
  let inActiveStyle = {
    textDecoration: 'none',
    color: inActiveStyleColor,
  }

  return (
    <>
      {showMenu ? (
        <Background>
          <animated.div style={animation}>
            <MobileMenuWrapper>
              <nav>
                <NavItems>
                  <li>
                    <NavLink
                      to='/'
                      style={({ isActive }) =>
                        isActive ? activeStyle : inActiveStyle
                      }
                      onClick={openMenu}>
                      {titleData[0].title}
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to='/work-history'
                      style={({ isActive }) =>
                        isActive ? activeStyle : inActiveStyle
                      }
                      onClick={openMenu}>
                      {titleData[1].title}
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to='/case-studies'
                      style={({ isActive }) =>
                        isActive ? activeStyle : inActiveStyle
                      }
                      onClick={openMenu}>
                      {titleData[2].title}
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to='/principles'
                      style={({ isActive }) =>
                        isActive ? activeStyle : inActiveStyle
                      }
                      onClick={openMenu}>
                      {titleData[3].title}
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to='/contact'
                      style={({ isActive }) =>
                        isActive ? activeStyle : inActiveStyle
                      }
                      onClick={openMenu}>
                      {titleData[4].title}
                    </NavLink>
                  </li>
                </NavItems>
              </nav>
              <DarkLightToggle toggleTheme={toggleTheme} />
            </MobileMenuWrapper>
          </animated.div>
        </Background>
      ) : null}
    </>
  )
}

export const DesktopMenu = ({ toggleTheme, theme }) => {
  const activeStyleColor =
    theme === 'dark' ? 'var(--color-primary)' : 'var(--color-secondary)'

  const inActiveStyleColor =
    theme === 'dark' ? 'var(--color-light)' : 'var(--color-dark)'

  let activeStyle = {
    textDecoration: 'underline 3px',
    textUnderlineOffset: '3px',
    color: activeStyleColor,
  }
  let inActiveStyle = {
    textDecoration: 'none',
    color: inActiveStyleColor,
  }

  return (
    <>
      <DesktopNavWrapper>
        <DesktopNav>
          <NavItems>
            <li>
              <NavLink
                to='/'
                style={({ isActive }) =>
                  isActive ? activeStyle : inActiveStyle
                }>
                {titleData[0].title}
              </NavLink>
            </li>
            <li>
              <NavLink
                to='/work-history'
                style={({ isActive }) =>
                  isActive ? activeStyle : inActiveStyle
                }>
                {titleData[1].title}
              </NavLink>
            </li>
            <li>
              <NavLink
                to='/case-studies'
                style={({ isActive }) =>
                  isActive ? activeStyle : inActiveStyle
                }>
                {titleData[2].title}
              </NavLink>
            </li>
            <li>
              <NavLink
                to='/principles'
                style={({ isActive }) =>
                  isActive ? activeStyle : inActiveStyle
                }>
                {titleData[3].title}
              </NavLink>
            </li>
            <li>
              <NavLink
                to='/contact'
                style={({ isActive }) =>
                  isActive ? activeStyle : inActiveStyle
                }>
                {titleData[4].title}
              </NavLink>
            </li>
          </NavItems>
          <DarkLightToggle toggleTheme={toggleTheme} />
        </DesktopNav>
      </DesktopNavWrapper>
    </>
  )
}
