import React from 'react'
import styled, { keyframes } from 'styled-components'
import { principleData } from 'data/data'
import { LayoutWrapper } from 'styles/global'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowUp, faArrowDown } from '@fortawesome/free-solid-svg-icons'
import Fade from 'react-reveal/Fade'
import ReactGA from 'react-ga'

const PrinicpleContainer = styled.div`
  display: grid;
  place-items: center;
  height: 90vh;
  width: 100%;
`
const IntroContainer = styled(PrinicpleContainer)`
  place-items: start;
  padding-top: 7em;
  @media (max-width: 960px) {
    padding-top: 10em;
  }
`
const Intro = styled.div`
  text-align: center;
  font-size: 1.3em;
  font-weight: 300;
`
const IntroHeader = styled.h2`
  line-height: 1.2;
  padding-bottom: 0.5em;
`
const PrincipleTitle = styled.h1`
  text-align: center;
  font-size: 2.5em;
  line-height: 1.2;
  margin-bottom: 0.5em;
  color: ${({ theme }) => theme.textHighlight};
`
const PrincipleDetails = styled.p`
  text-align: center;
  font-size: 1.2em;
`
const Citation = styled.p`
  font-size: 0.8em;
  text-align: center;
  padding-top: 1em;
  font-style: italic;
`
const BackToTop = styled.div`
  text-align: center;
  font-size: 1em;
  margin-top: 70px;
  padding-bottom: 80px;
  cursor: pointer;
`
const blinker = keyframes`
  50% { padding-bottom: 15px; }
`
const DownArrow = styled.div`
  display: grid;
  width: 100%;
  place-items: center;
  animation: ${blinker} 1s linear infinite;
`

const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: 'smooth',
  })
}

const PrinciplesContent = principleData.map((principle, index) => {
  return (
    <PrinicpleContainer key={principle.title}>
      <Fade
        bottom
        duration={2000}
        onReveal={() => {
          ReactGA.event({
            category: 'Text',
            action: `Viewed principle #${index + 1}`,
            label: 'Principles',
          })
        }}>
        <div>
          <PrincipleTitle>
            {index + 1}. <br />
            {principle.title}
          </PrincipleTitle>
          <PrincipleDetails>{principle.details}</PrincipleDetails>
          {principle.cite1 && principle.citeUrl && principle.cite2 && (
            <Citation>
              {principle.cite1}{' '}
              <a href={principle.citeUrl}>{principle.citeAnchor}</a>
              {principle.cite2}
            </Citation>
          )}
        </div>
      </Fade>
    </PrinicpleContainer>
  )
})

export default function Principles() {
  return (
    <>
      <LayoutWrapper principles>
        <IntroContainer>
          <Intro>
            <IntroHeader>Principles that guide my work</IntroHeader>
            Over the years I’ve come to lean on a set of principles that guide
            me in how I work and make decisions. All of them I’ve picked up from
            working alongside some amazing leaders, researchers, designers,
            engineers, product people, and more.
          </Intro>
          <DownArrow>
            <FontAwesomeIcon icon={faArrowDown} size='1x' />
          </DownArrow>
        </IntroContainer>
        {PrinciplesContent}
        <BackToTop onClick={scrollToTop}>
          <FontAwesomeIcon icon={faArrowUp} />
          <br />
          Back to the top
        </BackToTop>
      </LayoutWrapper>
    </>
  )
}
