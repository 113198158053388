import React from 'react'
import {
  LayoutWrapper,
  Back,
  Role,
  Header,
  Intro,
  Logo,
  BodyGrid,
  SummaryGrid,
  SummaryBox,
  ActivityGrid,
  Activity,
  SectionHeader,
  ActivityHeader,
  Paragraph,
  Emphasis,
  NumberedList,
  Image,
} from './casestudies.styled'
import { caseStudyData } from 'data/data'
import CaseStudyFooter from 'components/caseStudies/CaseStudyFooter'
import { Link } from 'react-router-dom'
import CaseStudyNumbers from 'components/caseStudies/CaseStudyNumbers'

export default function BabylonHealth({ theme }) {
  const caseStudyIndex = 0
  const caseStudyId = caseStudyData[caseStudyIndex].id
  const caseStudyHeadline = caseStudyData[caseStudyIndex].headline

  return (
    <>
      <LayoutWrapper caseStudy>
        <Back>
          <Link to='/case-studies' style={{ textDecoration: 'none' }}>
            Case Studies / Babylon Health{' '}
          </Link>
        </Back>

        <Header>{caseStudyHeadline}</Header>

        <Role>
          <Emphasis>Research + Service Design · 6 min read</Emphasis>
        </Role>

        <Intro>
          <Logo src={`/img/logos/Babylon_Logo_${theme}.png`} />
          Babylon Health is a digital-first health service provider that
          combines an artificial intelligence powered platform with virtual
          clinical operations for patients.
        </Intro>
        <SummaryGrid>
          <SummaryBox borderRight>
            <span>What we did</span>
            <br />
            Ran a 6-month phase of discovery research and design exploration to
            understand what people <i>really</i> need from a digital health
            partner.
          </SummaryBox>
          <SummaryBox borderRight>
            <span>What we achieved</span>
            <br />
            Outlined a series of clear opportunity areas for the business to
            focus on and to guide future strategic product decisions.
          </SummaryBox>
          <SummaryBox>
            <span>What I personally learned</span>
            <br />
            Striking a balance between the accuracy and efficiency of analysis
            needs to be considered upfront, to save time later on in the
            project.
          </SummaryBox>
        </SummaryGrid>

        <BodyGrid>
          <SectionHeader>Background</SectionHeader>

          <Paragraph>
            At the heart of Babylon Health is the app. A key component of the
            app is an A.I. powered chatbot that intends to augment the early
            fact-finding conversations a doctor has with their patient. It asks
            about the user's symptoms, attempts to identify the cause of their
            health concern, and then directs them to the right level of care. If
            the patient needs to see a doctor, the app connects them to the next
            available doctor via its telehealth service. This process is part of
            what Babylon calls its 'Circle of Care'.
            <br />
            <br />
            When I joined the A.I. chatbot team at Babylon Health, the focus in
            the team was heavily geared towards improving the relevancy of the
            chatbot's questions and the accuracy of its subsequent advice. This
            was a highly collaborative effort between the UX research team, the
            Babylon doctors, and the machine learning engineers.
            <br />
            <br />
            However, despite this flagship product being very popular in theory
            –{' '}
            <Emphasis>
              {' '}
              an app that could diagnose the cause of your health concern and
              tell you what to do next{' '}
            </Emphasis>{' '}
            – usage data suggested the product was not living up to the hype.
            People didn't understand the information provided and didn’t trust
            the results. In essence, it lacked utility. It was not proving to be
            useful in the context of people's health concerns.
            <br />
            <br />
            Babylon, much like many mid-stage startups, had grown from a small
            group of visionaries to a global 1500+ person company in only a
            handful of years. The growth was so rapid that the business hadn’t
            stopped to ask itself one of the more fundamental questions:{' '}
            <Emphasis>
              why does anyone need an A.I. powered symptom checker in the first
              place?
            </Emphasis>
          </Paragraph>
        </BodyGrid>

        <Image
          src='/img/babylon/babylon-animated-screen.gif'
          height='var(--large-image)'
          position='50% 30%'
        />

        <BodyGrid>
          <SectionHeader>Discovery research</SectionHeader>

          <Paragraph>
            Along with my colleague Maria (service design) we set about making
            the case for a phase of discovery research and design exploration
            that sought to answer some fundamental questions about health and
            wellbeing, in an attempt to understand what unmet-needs people had
            for an A.I. powered doctor in your pocket.
            <br />
            <br />
            We were given the time, space, and budget to run a deep dive
            mixed-method discovery (focused on the UK market), centered around
            answering two core research questions:
            <NumberedList>
              <Emphasis>
                <li>What shapes people’s health beliefs?</li>
                <li>
                  What do people need at the point at which a health concern
                  arises?
                </li>
              </Emphasis>
            </NumberedList>
            <br />
            Below are some of the activities we undertook and the outcomes of
            those activities, that formed the basis of our discovery.
          </Paragraph>
        </BodyGrid>
        <BodyGrid margin='0 0 30px 0'>
          <div></div>
          <ActivityGrid>
            <ActivityHeader>Activity</ActivityHeader>
            <ActivityHeader>Outcome</ActivityHeader>
            <Activity>Secondary research</Activity>
            <div>
              Digging into medical journals and previous papers, as well as
              reading through prior research the business had carried out,
              helped broaden our understanding of the problem space and gave us
              a strong starting point for our inquiry.
            </div>
            <Activity>Stakeholder interviews</Activity>
            <div>
              We needed to understand the perceived problems from the
              perspective of the business, and these interviews gave us a deeper
              understanding of the problem space seen through the eyes of
              stakeholders across the business, and up and down the chain of
              command.
            </div>
            <Activity>Health survey</Activity>
            <div>
              We ran a survey with a sample of approximately 3k people in an
              attempt to understand the differences in health beliefs,
              attitudes, and health-seeking behaviors in the UK population. We
              segmented the survey data based on our research objectives and
              used this to help us recruit participants for qualitative
              interviews.
            </div>
            <Activity>
              In-context interviews and observation (non-Babylon users)
            </Activity>
            <div>
              These interviews were the true “meat and potatoes” of the
              discovery. We carried out 16 home visits across six regions of the
              UK, with a broad selection of ages, health attitudes, and
              health-seeking behaviors. The insights gave us a deep
              understanding of how different people in the UK view their health
              as well as their experience with the healthcare system at large.
            </div>
            <Activity>Remote interviews with existing Babylon users</Activity>
            <div>
              In order to compare and contrast the experiences of people who
              have little-to-no experience with A.I. assisted healthcare with
              those who have had said experience, we conducted interviews with
              existing Babylon members. These interviews allowed us to look at
              the research questions through the eyes of people already using
              A.I. assisted healthcare to determine if any unmet needs differed.
            </div>
            <Activity>Remote interviews with healthcare professionals</Activity>
            <div>
              Patients engaging with the healthcare system were only one side of
              the story. By conducting interviews with healthcare professionals
              we were able to understand the experiences and pain points of
              patients through the eyes of those charged with their care.
            </div>
            <Activity>Analysis and synthesis</Activity>
            <div>
              Armed with all of this data and insight, we locked ourselves in a
              “war-room“ for several weeks while we worked on making sense of
              the rich tapestry of health contexts and human experiences we had
              uncovered. This deepened our understanding of the problem space
              and our shared collective understanding of the opportunities areas
              we saw before us.
            </div>
          </ActivityGrid>
        </BodyGrid>
        <CaseStudyNumbers
          header='The discovery in numbers'
          numbersData={[
            { number: 45, label: 'interviews completed' },
            { number: 14, label: 'colleagues involved in hands-on research' },
            { number: 950, label: 'miles travelled across the UK' },
            { number: '160+', label: 'hours of analysis' },
            { number: '8-10', label: 'existential debates during analysis' },
            { number: '1500+', label: 'reach of insights' },
          ]}
        />
        <Image
          src='/img/babylon/babylon-analysis.jpg'
          height='var(--medium-image)'
          position='30% 32%'
        />
        <BodyGrid>
          <SectionHeader>Sharing the wealth</SectionHeader>

          <Paragraph>
            Once our key themes had emerged from our analysis/synthesis, we
            began to weave those insights into a narrative that would land with
            our broad audience of stakeholders, as well as the wider business.
            <br />
            <br />
            However as so much work and effort had gone into uncovering a haul
            of rich insights, we felt that a series of presentations, workshops,
            and reports, would not do the discovery justice. We knew the story
            needed a bigger platform, so we set about designing an exhibition to
            showcase the insights to the whole company.
            <br />
            <br />
            We commandeered a few walls in the main meeting space at the Babylon
            HQ and also a second office, and set about designing an exhibition
            that told the story of our participants’ health journeys, health
            beliefs, and the context in which they experience and deal with
            health concerns.
            <br />
            <br />
            It was largely self-guided, but to give people more context, we ran
            a series of 30-minute guided tours for anyone in the business to
            join, in which we walked through not only the insights but also the
            discovery process and how we carried out the research.
            <br />
            <br />
            All of this was supported by a marketing effort in and around the
            company with posters and teaser videos to get people engaged and
            excited to attend the tours.
            <br />
            <br />
            The exhibition was hugely rewarding. It got many colleagues in the
            business thinking about the people on the end of our products and
            services. It lent insight to initiatives happening in other teams,
            as well as prompting new initiatives that may not have happened
            otherwise.
            <br />
            <br />
            Most of all, it reminded people in the business that we are not our
            users, and we cannot assume we know or understand their problems and
            the context in which they experience those problems –{' '}
            <a href='/principles'>see my guiding principles.</a>
          </Paragraph>
        </BodyGrid>
        <Image
          src='/img/babylon/babylon-exhibit.jpg'
          height='var(--medium-image)'
          position='20% 50%'
        />
        <BodyGrid>
          <SectionHeader>Turning insights into action</SectionHeader>

          <Paragraph>
            In parallel with the exhibition, we ran a series of ideation
            workshops with the core team and stakeholders, to take some of the
            key problem statements that came out of the research, and to start
            to think about solutions to solve those problems.
            <br />
            <br />
            Part of the goal of the workshop was intended to generate solutions,
            but also to socialize the insights into the wider team to get them
            to engage with the problems head-on.
            <br />
            <br />
            The workshops produced a slew of ideas and concepts that addressed
            the problem statements the teams were given, which we then usability
            tested with participants to get early feedback.
            <br />
            <br />
            By the end of the workshops, we had a series of solid ideas that
            were then taken into a phase of further design exploration.
          </Paragraph>
        </BodyGrid>

        <Image
          src='/img/babylon/babylon-workshop.jpg'
          height='var(--medium-image)'
          position='50% 24%'
        />

        <BodyGrid>
          <SectionHeader>Outcome</SectionHeader>

          <Paragraph>
            By the end of the discovery, we had successfully moved the business
            forward in understanding some of the fundamental unmet needs of
            their existing and potential user base. We had a set of strong
            concepts and ideas that could be explored further, and that would
            impact future strategy and road map planning.
            <br />
            <br />
            However, we faced one sizable problem. The ideas we had introduced,
            to a degree, undermined much of the current efforts of an
            expensively assembled 70+ strong team. Those responsible for
            defining the future vision and strategy of the product had taken the
            insights onboard, but were not willing to take action.
            <br />
            <br />
            We convened and decided that we needed to re-frame the narrative in
            a way that would be more likely to land with the senior team. We
            pulled together a presentation that painted a picture of what we
            perceived to be the risk of inaction.
            <br />
            <br />
            After delivering the presentation to the senior team, the message
            began to land as we’d hoped, and our learning from this was that
            despite crafting the narrative in a way we thought was compelling,
            we had not delivered the message in a language and in a context that
            worked for the key decision-makers.
            <br />
            <br />
            All in all I was proud of what we had achieved, and the impact the
            work had on product roadmaps across the entire organization. Work
            that is continuing to be realized today.
          </Paragraph>
        </BodyGrid>
      </LayoutWrapper>
      <CaseStudyFooter caseStudyId={caseStudyId} />
    </>
  )
}
