import styled, { css } from 'styled-components'

export const LayoutWrapper = styled.div`
  margin-top: 70px;
  display: grid;
  grid-template-columns:
    minmax(1.2rem, 1fr)
    minmax(auto, 57ch)
    minmax(1.2rem, 1fr);
  @media (max-width: 600px) {
    margin-top: 20px;
  }
`
export const Paragraph = styled.div`
  white-space: pre-line;
`
export const Role = styled.p`
  font-size: 0.8em;
  line-height: 1;
  margin-bottom: 25px;
  grid-column: 2;
`
export const Back = styled.p`
  font-size: 0.8em;
  line-height: 1;
  margin-bottom: 25px;
  grid-column: 2;
  :before {
    content: '←';
    color: ${({ theme }) => theme.text};
    padding-right: 5px;
  }
  a {
    text-decoration: none;
    color: ${({ theme }) => theme.text};
  }
`
export const Header = styled.h1`
  font-size: 2.5em;
  max-width: 700px;
  line-height: 1;
  margin-bottom: 20px;
  grid-column: 2;
`
export const SectionHeader = styled.h3`
  font-weight: 600;
  font-size: 1.3em;
  font-family: var(--primary-font);
  line-height: 1.3;
`
export const Intro = styled.div`
  grid-column: 1/4;
  padding: 20px;
  border-left: 4px solid #4433ff;
  background: ${({ theme }) => theme.callout};
  @media (min-width: 600px) {
    grid-column: 2;
    border-radius: 1rem;
    border-left: 4px solid #4433ff;
  }
`
export const BodyGrid = styled.div`
  grid-column: 2;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  margin: ${props => props.margin || '30px 0'};
  grid-gap: 1em 0;
  @media (min-width: 600px) {
    grid-template-columns: 130px 1fr;
    grid-gap: 1em;
  }
`
export const SummaryGrid = styled.div`
  grid-column: 1/4;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  grid-gap: 0;
  margin: 0;
  padding: 14px;
  background: ${({ theme }) => theme.caseStudySummaryBackground};
  font-size: 0.9em;
  @media (min-width: 600px) {
    grid-column: 2;
    grid-template-columns: 1fr 1fr 1fr;
    margin: 20px 0;
    padding: 0;
    background: transparent;
  }
  div {
    padding: 10px;
    @media (min-width: 600px) {
      padding: 0 15px;
    }
  }
`
export const ActivityGrid = styled.div`
  display: grid;
  grid-column: 2;
  width: 100%;
  font-size: 0.9em;
  grid-template-columns: 1fr 2fr;
  grid-template-rows: auto;
  grid-gap: 2em 1em;
`
export const ActivityHeader = styled.div`
  font-size: 1.2em;
  font-weight: 700;
`
export const Activity = styled.div`
  font-weight: 600;
`
export const SummaryBox = styled.div`
  padding: 15px;
  font-size: 0.9em;
  span {
    font-size: 1.1em;
    font-weight: 600;
  }
  ${props =>
    props.borderRight &&
    css`
      border-right: 1px solid #dbdbdb;
    `}

  @media (max-width: 600px) {
    ${props =>
      props.borderRight &&
      css`
        border-right: none;
      `}
  }
`
export const Image = styled.img`
  grid-column: 1 / 4;
  width: 100%;
  max-width: 100ch;
  height: ${props => props.height || 'auto'};
  object-fit: cover;
  object-position: ${props => props.position || 'center'};
  justify-self: center;
  filter: ${({ theme }) => theme.imageFilter};
`
export const Emphasis = styled.span`
  font-style: italic;
  font-weight: 500;
`
export const Logo = styled.img`
  width: 100px;
  margin-bottom: 10px;
`
export const NumberedList = styled.ol`
  li {
    padding-top: 10px;
  }
`
