import React from 'react'
import styled from 'styled-components'

export default function Contact() {
  return (
    <Wrapper>
      <Container>
        If you have a job opportunity, you're seeking advice or mentorship, or just want to have a
        natter over a cup of tea, give me a whistle!
        <br />
        <Email href='mailto:jamestaylorson@gmail.com'>jamestaylorson@gmail.com</Email>
      </Container>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: grid;
  place-items: center;
  width: 100%;
  height: 100vh;
  text-align: center;
  padding: 10vw;
`
const Container = styled.div``
const Email = styled.a`
  font-size: 1.5rem;
`
