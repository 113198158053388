import styled from 'styled-components'

export const ShowHideBurger = styled.div`
  display: block;
  @media (min-width: 960px) {
    display: none;
  }
`

export const HitArea = styled.div`
  position: fixed;
  background: transparent;
  height: 100px;
  width: 100px;
  bottom: 0;
  right: 0;
  z-index: 500;
  cursor: pointer;
`

export const BurgerContainer = styled.div`
  position: fixed;
  display: grid;
  place-items: center;
  bottom: 25px;
  right: 25px;
  height: 60px;
  width: 60px;
  border-radius: 50px;
  background: ${({ theme }) => theme.burgerBackground};
  backdrop-filter: blur(2px);
  border: ${({ theme }) => theme.burgerBorder};
  cursor: pointer;
  z-index: 10;
`

export const AnimatedLines = styled.div`
  width: 2em;
  height: 25px;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.5s ease-in-out;
  -moz-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;

  span {
    display: block;
    position: absolute;
    height: 5px;
    width: 100%;
    background: ${({ theme }) => theme.burger};
    border-radius: 9px;
    opacity: 1;
    left: 0;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: 0.25s ease-in-out;
    -moz-transition: 0.25s ease-in-out;
    -o-transition: 0.25s ease-in-out;
    transition: 0.25s ease-in-out;

    :nth-child(1) {
      top: ${({ open }) => (open ? '10px' : '0px')};
      width: ${({ open }) => (open ? '0%' : null)};
      left: ${({ open }) => (open ? '50%' : null)};
    }

    :nth-child(2) {
      top: ${({ open }) => (open ? null : '10px')};
      -webkit-transform: ${({ open }) => (open ? 'rotate(45deg)' : null)};
      -moz-transform: ${({ open }) => (open ? 'rotate(45deg)' : null)};
      -o-transform: ${({ open }) => (open ? 'rotate(45deg)' : null)};
      transform: ${({ open }) => (open ? 'rotate(45deg)' : null)};
      top: ${({ open }) => (open ? '10px' : null)};
    }

    :nth-child(3) {
      top: ${({ open }) => (open ? null : '10px')};
      -webkit-transform: ${({ open }) => (open ? 'rotate(-45deg)' : null)};
      -moz-transform: ${({ open }) => (open ? 'rotate(-45deg)' : null)};
      -o-transform: ${({ open }) => (open ? 'rotate(-45deg)' : null)};
      transform: ${({ open }) => (open ? 'rotate(-45deg)' : null)};
      top: ${({ open }) => (open ? '10px' : null)};
    }

    :nth-child(4) {
      top: ${({ open }) => (open ? '10px' : '20px')};
      width: ${({ open }) => (open ? '0%' : null)};
      left: ${({ open }) => (open ? '50%' : null)};
    }
  }
`
