import React from 'react'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'

const Container = styled.button`
  width: fit-content;
  margin: 2em 0;
  padding: 1em 2em;
  border-radius: 50px;
  border: none;
  background: ${({ theme }) => theme.buttonGradient};
  line-height: 1;
  font-size: 1em;
  font-weight: 700;
  color: ${({ theme }) => theme.buttonText};
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.1s linear;
  :hover > div > div > svg {
    transform: translateX(5px);
    transition: all 0.1s linear;
  }
`

const Grid = styled.div`
  display: grid;
  grid-template-columns: 3fr 1fr;
  grid-gap: 0;
  align-items: center;
  justify-content: center;
  grid-template-areas: 'text arrow';
`
const Text = styled.div`
  grid-area: text;
  justify-self: start;
  white-space: nowrap;
`
const Arrow = styled.div`
  grid-area: arrow;
  justify-self: end;
  svg {
    transition: all 0.25s linear;
  }
`

export default function Button({ children, ...props }) {
  return (
    <Container {...props}>
      <Grid>
        <Text>{children}</Text>
        <Arrow>
          <FontAwesomeIcon icon={faArrowRight} />
        </Arrow>
      </Grid>
    </Container>
  )
}
