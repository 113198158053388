import React from 'react'
import styled from 'styled-components'

const Header = styled.h3`
  grid-column: 2;
  text-align: center;
  margin: 30px 0;
  font-weight: 600;
  font-size: 1.3em;
  font-family: var(--primary-font);
  line-height: 1.1;
`
const Parent = styled.div`
  grid-column: 2;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 3rem 1rem;
  margin-bottom: 5rem;
`
const Child = styled.div`
  flex: 0 1 280px;
  text-align: center;
`
const Number = styled.h2`
  font-size: 3rem;
`
const Label = styled.p`
  font-size: 1rem;
`

export default function CaseStudyNumbers({ header, numbersData }) {
  const data = numbersData.map(({ number, label }) => {
    return (
      <Child key={number + label}>
        <Number>{number}</Number>
        <Label>{label}</Label>
      </Child>
    )
  })

  return (
    <>
      <Header>{header}</Header>
      <Parent>{data}</Parent>
    </>
  )
}
