import React from 'react'
import styled from 'styled-components'

const Heading = styled.h1`
  text-align: center;
  font-size: 1.5em;
  margin: 1em 0;
  @media (min-width: 960px) {
    display: none;
  }
`

export default function Header({ text }) {
  return <Heading>{text}</Heading>
}
