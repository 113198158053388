import React, { useState } from 'react'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLongArrowAltRight } from '@fortawesome/free-solid-svg-icons'
import ReactGA from 'react-ga'

const Label = styled.div`
  font-size: 1.2rem;
  font-weight: 700;
  color: inherit;
  border: none;
  background: transparent;
  cursor: pointer;
  text-decoration: underline;
  text-underline-offset: 3px;
  text-decoration-thickness: 2px;
  text-decoration-color: inherit;
`
const Body = styled.div`
  font-size: 1rem;
  padding: 0.5rem 0;
  p {
    display: block;
    margin-bottom: 1rem;
  }
  a {
    color: inherit;
  }
`

export default function TextExpand({ text }) {
  const [isExpanded, setIsExpanded] = useState(false)

  const handleExpand = () => {
    setIsExpanded(toggle => !toggle)
    ReactGA.event({
      category: 'Expand',
      action: 'Clicked on Tell Me More toggle',
      label: 'Home',
    })
  }

  const expandSymbol = isExpanded ? '-' : '+'

  return (
    <>
      <Label onClick={handleExpand}>Tell me more {expandSymbol}</Label>
      {isExpanded && (
        <Body>
          <p>Hi there! &#128075;</p>
          <p>
            I’m James, a human-centered leader and practitioner focused on creating meaningful,
            measurable, real-world impact through UX research.{' '}
          </p>
          <p>
            I thrive both in leading research teams and in driving research programs, that deliver a
            deep and empathetic understanding of people's lives and unmet needs, and empower
            data-driven roadmaps that solve human problems.
          </p>
          <p>
            I spent the first decade of my working life in the ad agency world – both in London and
            New York – delivering digital media and websites for a host of blue chip clients,
            eventually heading up the digital department at a rapidly growing agency called{' '}
            <a
              href='https://www.oliver.agency'
              target='_blank'
              rel='noreferrer'
              style={{ color: 'inherit' }}
            >
              Oliver
            </a>
            .
          </p>
          <p>
            In 2015 I made the switch to the world of user-centered service and product design, and
            since then I’ve helped shape the future of an A.I. powered healthcare app at{' '}
            <a
              href='https://www.babylonhealth.com'
              target='_blank'
              rel='noreferrer'
              style={{ color: 'inherit' }}
            >
              Babylon
            </a>
            , simplified an immigration service for anxious post-Brexit applicants at{' '}
            <a
              href='https://www.gov.uk/government/organisations/home-office'
              target='_blank'
              rel='noreferrer'
              style={{ color: 'inherit' }}
            >
              The Home Office
            </a>
            , helped deliver a mobile payment service to Indian citizens for{' '}
            <a
              href='https://www.visa.co.in'
              target='_blank'
              rel='noreferrer'
              style={{ color: 'inherit' }}
            >
              Visa
            </a>
            , and built out a research function at fast-fashion retailer{' '}
            <a
              href='https://www.newlook.com'
              target='_blank'
              rel='noreferrer'
              style={{ color: 'inherit' }}
            >
              New Look
            </a>
            .
          </p>

          <p>
            If you would like to know more about my experience, or just fancy a chat{' '}
            <a href='mailto:jamestaylorson@gmail.com'>
              gimmie me a whistle
              <FontAwesomeIcon style={{ marginLeft: '5px' }} icon={faLongArrowAltRight} />
            </a>
          </p>
        </Body>
      )}
    </>
  )
}
