import React from 'react'
import styled, { css } from 'styled-components'
import { Link } from 'react-router-dom'
import ReactGA from 'react-ga'

const Container = styled.div`
  position: relative;
  height: 43vw;
  width: 86vw;
  max-height: 200px;
  max-width: 400px;
  border-radius: 5px;
  color: black;
  overflow: hidden;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  background: ${props =>
    props.bgColor === 'blue'
      ? 'var(--gradient-blue)'
      : props.bgColor === 'green'
      ? 'var(--gradient-green)'
      : props.bgColor === 'yellow'
      ? 'var(--gradient-yellow)'
      : props.bgColor === 'peach'
      ? 'var(--gradient-peach)'
      : 'var(--gradient-pink)'};
  :hover {
    transform: scale(1.02);
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  }
  @media (min-width: 960px) {
    height: 15vw;
  }
`
const elipseSize = '200px'
const Elipse = styled.div`
  position: absolute;
  width: ${elipseSize};
  height: ${elipseSize};
  top: -9px;
  background: #565656;
  mix-blend-mode: overlay;
  border-radius: 50%;
  right: ${props => (props.copyPlacement === 'right' ? '130px' : '-4px')};
  opacity: ${props => (props.bgColor === 'tealpink' ? '1' : '0.3')};
`
const Grid = styled.div`
  width: 100%;
  display: grid;
  padding: 16px;
  grid-gap: 10px;
  grid-template-columns: ${props =>
    props.copyPlacement === 'right' ? '2fr 3fr' : '3fr 2fr'};
  grid-template-areas: ${props =>
    props.copyPlacement === 'right'
      ? "'cardimage cardcopy'"
      : "'cardcopy cardimage'"};
`
const CardCopy = styled.div`
  grid-area: cardcopy;
  ${props =>
    props.copyRight &&
    css`
      padding-left: 17px;
    `}
`
const CardImage = styled.div`
  grid-area: cardimage;
`
const Image = styled.img`
  width: ${props => props.imgWidth || '50%'};
  position: absolute;
  top: 10px;
  ${props => (props.copyPlacement === 'left' ? 'right' : 'left')}: ${props =>
    props.imgPositionMobile || '10px'};
  @media (min-width: 960px) {
    ${props => (props.copyPlacement === 'left' ? 'right' : 'left')}: ${props =>
      props.imgPositionDesktop || '10px'};
  }
`
const CardHeader = styled.h4`
  font-size: 1.1em;
  line-height: 1.1;
  margin-block-start: 0;
  margin-block-end: 0;
  letter-spacing: 0.5px;
`
const CardBody = styled.p`
  font-size: 0.8em;
  line-height: 1.3;
  margin-block-start: 5px;
  margin-block-end: 0;
`

export default function CaseCard({ entry, ...props }) {
  return (
    <Link
      key={entry.id}
      to={`/case-studies/${entry.url}`}
      style={{ textDecoration: 'none' }}
      onClick={() => {
        ReactGA.event({
          category: 'Card',
          action: `clicked on ${entry.headline}`,
          label: 'Case Studies',
        })
      }}>
      <Container bgColor={entry.bgColor} {...props}>
        <Grid copyPlacement={entry.copyPlacement} {...props}>
          <CardCopy {...props}>
            <CardHeader>{entry.headline}</CardHeader>
            <CardBody>{entry.body}</CardBody>
          </CardCopy>
          <CardImage>
            <Image
              src={`/img/${entry.image}.png`}
              imgPositionMobile={entry.imgPositionMobile}
              imgPositionDesktop={entry.imgPositionDesktop}
              // imgPosition={entry.imgPosition}
              copyPlacement={entry.copyPlacement}
              imgWidth={entry.imgWidth}
            />
          </CardImage>
        </Grid>
        <Elipse
          copyPlacement={entry.copyPlacement}
          bgColor={entry.bgColor}
          {...props}
        />
      </Container>
    </Link>
  )
}
