import React from 'react'
import styled from 'styled-components'

const Container = styled.div`
  display: grid;
  width: 100%;
  height: 100vh;
  place-items: center;
  text-align: center;
  padding: 0 20vw;
`
const ComingSoonHeader = styled.h1`
  font-size: 2rem;
`
const ComingSoonBody = styled.p`
  font-size: 1rem;
`

export default function CaseStudyComingSoon() {
  return (
    <Container>
      <div>
        <ComingSoonHeader>Coming Soon!</ComingSoonHeader>
        <ComingSoonBody>
          This case study will be up soon. In the meantime, if you haven't
          already, you can read the case study:{' '}
          <a href='/case-studies/babylon-health'>
            'Redefining the purpose of an A.I. health service'
          </a>
          .
        </ComingSoonBody>
      </div>
    </Container>
  )
}
