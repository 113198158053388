import React from 'react'
import {
  BurgerContainer,
  AnimatedLines,
  HitArea,
  ShowHideBurger,
} from './Burger.styled'

const Burger = ({ showMenu, setShowMenu }) => {
  return (
    <ShowHideBurger>
      <HitArea onClick={() => setShowMenu(!showMenu)} />
      <BurgerContainer>
        <AnimatedLines open={showMenu}>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </AnimatedLines>
      </BurgerContainer>
    </ShowHideBurger>
  )
}

export default Burger
