import styled, { createGlobalStyle, css } from 'styled-components'

export const GlobalStyles = createGlobalStyle`

:root {
  --color-primary: #f2c94c;
  --color-secondary: #408fce;
  --color-dark: #161616;
  --color-light: #f5f5f5;
  --color-dark-callout: #182635;
  --color-light-callout: #dfebf6;
  --color-dark-tint: #000;
  --color-light-tint: #e3e3e3;
  --gradient-pink: linear-gradient(149deg,#74e2e4 4.21%,#fecccc 74.43%);
  --gradient-blue: linear-gradient(101.01deg,#4070a6 34.93%,#92bef4 92.71%);
  --gradient-green: linear-gradient(114.18deg,#f2e0a0 12.99%,#46ac81 45.17%);
  --gradient-yellow: linear-gradient(30deg, #7272b4 0%, #6d6178 16%, #7f5274 33%, #a37b6b 50%, #c3a65d 66%, #d0c44f 83%, #dada96 100%);
  --gradient-peach: linear-gradient(90deg, #ffd7b8, #edbca9, #d7a29d, #bd8b92, #a17587, #82627b, #62516d, #42405c);
  --primary-font: 'Wotfard', 'Futura','-apple-system',sans-serif;
  --secondary-font: 'Kanit', 'Futura','-apple-system',sans-serif;
  --small-image: 200px;
  --medium-image: 300px;
  --large-image: 500px;
}

  *,
  *::after,
  *::before {
    box-sizing: border-box;
  }

  * {
  margin: 0;
}

*:focus {
  outline: none;
}
::-webkit-scrollbar {
  display: none;
}

html {
  font-family: var(--primary-font);
  font-weight: 400;
  font-size: 1em;
  height: 100%;}

  body {
    background: ${({ theme }) => theme.body};
    color: ${({ theme }) => theme.text};
    height: calc(100 * var(--vh));
    line-height: 1.5;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-tap-highlight-color: transparent;
    -webkit-overflow-scrolling: touch;
    touch-action: manipulation;
  }
  
a {
  text-underline-offset: 2px;
  text-decoration-thickness: 3px;
  text-decoration-color: ${({ theme }) => theme.textHighlight};
  color: inherit;
}

img,
picture,
video,
canvas,
svg {
  display: block;
  max-width: 100%;
}
input,
button,
textarea,
select {
  font: inherit;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--secondary-font);
  font-weight: 700;
  margin-block-start: 0px;
    margin-block-end: 0px;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
}
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  overflow-wrap: break-word;
}
#root,
#__next {
  isolation: isolate;
}

ul { margin: 0; padding: 0; }

.conversational-form {
  font-family: var(--primary-font) !important;
  font-style: normal;
  background: ${({ theme }) => theme.body} !important;
}
cf-input {
  background: ${({ theme }) => theme.body} !important;
}
`

export const LayoutWrapper = styled.section`
  margin: 0 auto;
  max-width: 960px;

  ${props =>
    props.home &&
    css`
      padding: 100px 15vw 15vw 15vw;
      @media (min-width: 960px) {
        padding: 150px 250px 0 50px;
      }
      @media (min-width: 1100px) {
        padding: 150px 250px 0 0;
      }
    `}

  ${props =>
    props.workHistory &&
    css`
      padding: 0 10vw 25px;
      @media (min-width: 960px) {
        padding: 100px 10vw;
      }
    `}

    ${props =>
    props.caseStudies &&
    css`
      padding: none;
      @media (min-width: 960px) {
        padding: 125px 50px;
      }
    `}

    ${props =>
    props.caseStudy &&
    css`
      padding: 30px;
      font-family: var(--secondary-font);
      line-height: 1.8;
      @media (min-width: 960px) {
        padding: 125px 50px;
      }
    `}

    ${props =>
    props.principles &&
    css`
      padding: 0 10vw;
      @media (min-width: 960px) {
        padding: 100px 18vw;
      }
    `}
`
