import React from 'react'
import styled from 'styled-components'

const HitArea = styled.div`
  position: fixed;
  background: transparent;
  height: 100px;
  width: 100px;
  bottom: 0;
  left: 0;
  z-index: 500;
  cursor: pointer;
  @media (min-width: 960px) {
    top: 0;
    left: inherit;
    right: 0;
    height: 60px;
    width: 60px;
  }
`
const ToggleContainer = styled.div`
  position: fixed;
  display: grid;
  place-items: center;
  bottom: 25px;
  left: 25px;
  height: 60px;
  width: 60px;
  border-radius: 50px;
  background: rgb(255, 255, 255);
  border: 6px ${({ theme }) => theme.themeToggleBorder};
  background: ${({ theme }) => theme.themeToggleBackground};
  cursor: pointer;
  z-index: 10;
  @media (min-width: 960px) {
    border: 3px ${({ theme }) => theme.themeToggleBorder};
    top: 10px;
    left: inherit;
    right: 20px;
    height: 20px;
    width: 20px;
  }
`

export default function DarkLightToggle({ toggleTheme }) {
  return (
    <>
      <HitArea onClick={toggleTheme} />
      <ToggleContainer />
    </>
  )
}
